import React, { useState } from 'react';
import { Grid, Paper, Typography, FormControlLabel, Switch, Box, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, ResponsiveContainer } from 'recharts';
import { ProduktStat } from '..';

interface TopProductsChartProps {
    data: ProduktStat[];
    dataAbgeschlossen: ProduktStat[];
}

const TopProductsChart: React.FC<TopProductsChartProps> = ({ data, dataAbgeschlossen }) => {
    const [showAbgeschlossen, setShowAbgeschlossen] = useState(false);

    const infoText = "Zeigt die am häufigsten gebuchten Produkte an. Mit dem Schalter können Sie zwischen allen Buchungen und nur abgeschlossenen Buchungen wechseln. Das Produkt muss mindestens 2 Mal gebucht werden um in der Liste angezeigt zu werden.";

    return (
        <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6">
                            Beliebteste Produkte
                        </Typography>
                        <Tooltip title={infoText}>
                            <IconButton size="small">
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showAbgeschlossen}
                                onChange={(e) => setShowAbgeschlossen(e.target.checked)}
                            />
                        }
                        label="Nur abgeschlossene Bestellungen"
                    />
                </Box>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart 
                        data={showAbgeschlossen ? dataAbgeschlossen : data}
                        layout="vertical"
                        margin={{ left: 200 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis 
                            dataKey="name" 
                            type="category"
                            width={180}
                            tick={{ 
                                fontSize: 11,
                            }}
                        />
                        <ChartTooltip />
                        <Bar 
                            dataKey="anzahl" 
                            fill="#8884d8" 
                            name={showAbgeschlossen ? "Anzahl abgeschlossene Buchungen" : "Anzahl Buchungen"} 
                        />
                    </BarChart>
                </ResponsiveContainer>
            </Paper>
        </Grid>
    );
};

export default TopProductsChart; 