import React from 'react';
import { Grid, Paper, Typography, IconButton, Tooltip, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, ResponsiveContainer } from 'recharts';
import { MuseumStat } from '../types';

interface MuseumStatsChartProps {
    title: string;
    dataKey: keyof MuseumStat;
    data: MuseumStat[];
    color: string;
    selectedMuseums: number[];
    infoText?: string;
}

const MuseumStatsChart: React.FC<MuseumStatsChartProps> = ({
    title,
    dataKey,
    data,
    color,
    selectedMuseums,
    infoText
}) => (
    <Grid item xs={12} md={4}>
        <Paper sx={{ p: 2 }}>
            <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {title}
                </Typography>
                {infoText && (
                    <Tooltip title={infoText}>
                        <IconButton size="small">
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            {selectedMuseums.length === 0 ? (
                <Typography color="text.secondary" align="center">
                    Bitte wählen Sie mindestens ein Museum aus
                </Typography>
            ) : (
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                            dataKey="name" 
                            angle={-45}
                            textAnchor="end"
                            height={120}
                            interval={0}
                            tick={{ 
                                fontSize: 11,
                                dy: 10
                            }}
                        />
                        <YAxis />
                        <ChartTooltip />
                        <Bar dataKey={dataKey} fill={color} name={title} />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </Paper>
    </Grid>
);

export default MuseumStatsChart; 