import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';

interface StatsHighlightProps {
    title: string;
    value: string;
    unit?: string;
}

const StatsHighlight: React.FC<StatsHighlightProps> = ({ title, value, unit }) => (
    <Grid item xs={12} sm={3}>
        <Paper sx={{ p: 2, height: '100%', bgcolor: '#f5f5f5' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
                    {value}
                </Typography>
                {unit && (
                    <Typography variant="caption" color="textSecondary">
                        {unit}
                    </Typography>
                )}
            </Box>
        </Paper>
    </Grid>
);

export default StatsHighlight; 