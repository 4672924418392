import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, DateTimeInput } from 'react-admin';
import moment from 'moment-timezone';

// Set default timezone like in sendEmail.ts
moment.tz.setDefault("Europe/Berlin");

export const TerminvorschlagEdit = (props: any) => (
    <Edit 
        {...props} 
        mutationMode="pessimistic"
        mutationOptions={{
            onSuccess: (data: any) => {
                console.log('Mutation success data:', data);
                return `/bestellung/${data.bestellung_id}/show`;
            }
        }}
    >
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput disabled source="bestellung_id" reference="bestellung">
                <SelectInput optionText="id" />
            </ReferenceInput>
            <DateTimeInput
                disabled
                source="vorschlagdatetime"
                inputProps={{
                    format: 'DD.MM.YYYY HH:mm',
                    ampm: false,
                }}
                parse={(value) => {
                    if (!value) return null;
                    return moment.tz(value, "Europe/Berlin").toISOString();
                }}
                format={(value) => {
                    if (!value) return '';
                    return moment.utc(value)
                        .tz("Europe/Berlin")
                        .format('YYYY-MM-DDTHH:mm:00');
                }}
            />
            <SelectInput source="status" choices={[
                { id: 'vorgeschlagen', name: 'Vorgeschlagen' },
                { id: 'akzeptiert', name: 'Akzeptiert' },
                { id: 'abgelehnt', name: 'Abgelehnt' },
            ]} />
        </SimpleForm>
    </Edit>
);
