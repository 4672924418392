import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Paper, Grid } from '@mui/material';
import { FilterState } from '..';

interface YearSelectorProps {
    filters: FilterState;
    availableYears: number[];
    setFilters: (filters: FilterState) => void;
}

const YearSelector: React.FC<YearSelectorProps> = ({ filters, availableYears, setFilters }) => (
    <Paper sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
                <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                        value={filters.year}
                        onChange={(e) => setFilters({ ...filters, year: Number(e.target.value) })}
                    >
                        {availableYears.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    </Paper>
);

export default YearSelector; 