import React, { useState } from 'react';
import { Grid, Paper, Typography, Box, FormControlLabel, Switch, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, ResponsiveContainer } from 'recharts';
import { MitarbeiterStat } from '..';

interface TopMitarbeiterChartProps {
    data: MitarbeiterStat[];
}

const TopMitarbeiterChart: React.FC<TopMitarbeiterChartProps> = ({ data }) => {
    const [showAbgeschlossen, setShowAbgeschlossen] = useState(false);

    const infoText = "Zeigt die Anzahl der zugewiesenen Termine pro Mitarbeiter:in an. Mit dem Schalter können Sie zwischen allen zugewiesenen Terminen und nur abgeschlossenen Terminen wechseln.";

    return (
        <Grid item xs={12} >
            <Paper sx={{ p: 2 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6">
                            Zugewiesene Termine pro Mitarbeiter:in
                        </Typography>
                        <Tooltip title={infoText}>
                            <IconButton size="small">
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showAbgeschlossen}
                                onChange={(e) => setShowAbgeschlossen(e.target.checked)}
                            />
                        }
                        label="Nur abgeschlossene Termine"
                    />
                </Box>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                            dataKey="name"
                            angle={-45}
                            textAnchor="end"
                            height={100}
                            interval={0}
                            tick={{ 
                                fontSize: 9,
                                dy: 8,
                                dx: -8
                            }}
                        />
                        <YAxis />
                        <ChartTooltip />
                        <Bar 
                            dataKey={showAbgeschlossen ? "anzahlAbgeschlossen" : "anzahl"} 
                            fill="#8884d8" 
                            name={showAbgeschlossen ? "Abgeschlossene Termine" : "Alle Termine"} 
                        />
                    </BarChart>
                </ResponsiveContainer>
            </Paper>
        </Grid>
    );
};

export default TopMitarbeiterChart; 