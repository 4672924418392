import React from 'react';
import { Paper, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

interface MuseumSelectorProps {
    museums: any[];
    selectedMuseums: number[];
    setSelectedMuseums: (museums: number[]) => void;
}

const MuseumSelector: React.FC<MuseumSelectorProps> = ({ 
    museums, 
    selectedMuseums, 
    setSelectedMuseums 
}) => (
    <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
            Museen auswählen
        </Typography>
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={selectedMuseums.length === museums.length}
                        indeterminate={selectedMuseums.length > 0 && selectedMuseums.length < museums.length}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedMuseums(museums.map(m => m.id));
                            } else {
                                setSelectedMuseums([]);
                            }
                        }}
                    />
                }
                label="Alle"
            />
            {museums.map(museum => (
                <FormControlLabel
                    key={museum.id}
                    control={
                        <Checkbox
                            checked={selectedMuseums.includes(museum.id)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedMuseums([...selectedMuseums, museum.id]);
                                } else {
                                    setSelectedMuseums(selectedMuseums.filter(id => id !== museum.id));
                                }
                            }}
                        />
                    }
                    label={museum.name}
                />
            ))}
        </FormGroup>
    </Paper>
);

export default MuseumSelector; 