import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const Settings: React.FC = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Einstellungen
      </Typography>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Systemeinstellungen
          </Typography>
          <Typography>
            Hier finden Sie verschiedene Einstellungsmöglichkeiten für das System.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Settings; 