import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Paper,
  FormControlLabel,
  Switch,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { supabase } from '../supabaseClient';

// Default text blocks that can be restored
const defaultTextBlocks = {
  'Stornierungsbedingungen': {
    title: 'Stornierungsbedingungen',
    content: `<p><strong>Wichtige Hinweise:</strong></p>
    <p>Bitte beachten Sie, dass eine <strong>kostenfreie Änderungs- und Stornierungsmöglichkeit nur bis drei Werktage vor dem jeweiligen Termin</strong> besteht. Bei einer rechtzeitigen Stornierung müssen wir Ihnen den Verwaltungsaufwand von 30 € in Rechnung stellen. Erfolgt die Stornierung zu kurzfristig (weniger als drei Werktage) berechnen wir Ihnen unsere Unkosten in Höhe von 70 € pro gebuchter Stunde.</p>`
  },
  'Verspätungshinweis': {
    title: 'Verspätungshinweis',
    content: `<p>Im Fall einer Verspätung bitten wir Sie, unter o. g. Handy-Nummern Bescheid zu geben. Die Museumspädagog:in wartet bis 15 Minuten nach dem vereinbarten Termin, so dass sich die Dauer der Veranstaltung gegebenenfalls entsprechend verkürzt. Wird die Veranstaltung nicht wahrgenommen, entfällt die vereinbarte Leistung, und wir müssen die Unkosten ebenfalls (s. o.) in Rechnung stellen.</p>`
  },
  'Kontaktinformationen': {
    title: 'Kontaktinformationen',
    content: `<p><strong>Bei Absage oder Verlegung des Termins</strong> bitten wir Sie um rechtzeitige Nachricht per Mail an <a href="mailto:theresa.heyer@pforzheim.de">theresa.heyer@pforzheim.de</a> oder rufen Sie uns unter Tel. 07231 / 39 2079 an.</p>`
  },
  'Standardsignatur': {
    title: 'Standardsignatur',
    content: `<p>Mit freundlichen Grüßen<br>Theresa Heyer (Beauftragte für Kulturelle Bildung)</p>`
  },
  'Stornierungserklärung': {
    title: 'Stornierungserklärung',
    content: `<h2>Wie geht es nun weiter?</h2>
    <p>Sollte die Stornierung nicht auf Ihren Wunsch erfolgt sein, stehen an den von Ihnen gewünschten Terminen leider nicht genügend museumspädagogische Fachkräfte zur Verfügung.</p>
    <p>Kontaktieren Sie uns bitte erneut wegen eines neuen Termins oder nutzen Sie unsere Online Terminbuchung</p>`
  }
};

interface TextBlock {
  id: number;
  title: string;
  content: string;
  created_at: string;
}

interface GeneralSetting {
  key: string;
  value: boolean;
  description: string;
}

// Quill editor modules configuration
const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link'],
    ['clean']
  ]
};

const quillFormats = [
  'header',
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'link'
];

const EmailTextBlockEditor: React.FC = () => {
  const [textBlocks, setTextBlocks] = useState<TextBlock[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<TextBlock | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [alert, setAlert] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [confirmDialog, setConfirmDialog] = useState<{ open: boolean, action: () => void, message: string }>({
    open: false,
    action: () => {},
    message: ''
  });
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [{ data: blocks }, { data: settings }] = await Promise.all([
      supabase.from('email_text_blocks').select('*').order('title'),
      supabase.from('general_settings').select('*').eq('key', 'send_durchfuehrbarkeit_notification').single()
    ]);

    if (blocks) setTextBlocks(blocks);
    if (settings) setNotificationEnabled(settings.value);
  };

  const handleToggleNotification = async (checked: boolean) => {
    const { error } = await supabase
      .from('general_settings')
      .update({ value: checked })
      .eq('key', 'send_durchfuehrbarkeit_notification');

    if (error) {
      console.error('Error updating setting:', error);
      return;
    }

    setNotificationEnabled(checked);
  };

  const handleEdit = (block: TextBlock) => {
    setSelectedBlock(block);
    setIsEditing(true);
  };

  const handleSave = async () => {
    if (!selectedBlock) return;

    const { error } = await supabase
      .from('email_text_blocks')
      .upsert({
        id: selectedBlock.id,
        title: selectedBlock.title,
        content: selectedBlock.content
      });

    if (error) {
      setAlert({ type: 'error', message: 'Fehler beim Speichern des Textblocks' });
    } else {
      setAlert({ type: 'success', message: 'Textblock gespeichert' });
      fetchData();
      setIsEditing(false);
    }
  };

  const handleRestoreDefault = (block: TextBlock) => {
    setConfirmDialog({
      open: true,
      message: 'Sind sie sicher, dass sie den Textblock zurücksetzen wollen?',
      action: async () => {
        const defaultBlock = defaultTextBlocks[block.title as keyof typeof defaultTextBlocks];
        if (defaultBlock) {
          const { error } = await supabase
            .from('email_text_blocks')
            .update({
              content: defaultBlock.content
            })
            .eq('title', block.title);

          if (error) {
            setAlert({ type: 'error', message: 'Fehler beim Zurücksetzen des Textblocks' });
          } else {
            setAlert({ type: 'success', message: 'Textblock zurückgesetzt' });
            fetchData();
          }
        }
        setConfirmDialog({ open: false, action: () => {}, message: '' });
      }
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Email Textbausteine
      </Typography>

      {alert && (
        <Alert 
          severity={alert.type} 
          onClose={() => setAlert(null)}
          sx={{ mb: 2 }}
        >
          {alert.message}
        </Alert>
      )}

      {/* Settings Section */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Einstellungen
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={notificationEnabled}
              onChange={(e) => handleToggleNotification(e.target.checked)}
            />
          }
          label="Durchführbarkeits-Benachrichtigung aktivieren"
        />
        <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
          Wenn aktiviert, werden Kunden per E-Mail benachrichtigt, sobald ihre Bestellung durchführbar ist.
        </Typography>
      </Paper>

      <Divider sx={{ my: 3 }} />

      <Paper elevation={0} sx={{ bgcolor: 'background.default' }}>
        {textBlocks.map((block, index) => (
          <Box key={block.id}>
            <Box sx={{ py: 3 }}>
              <Typography variant="h6" gutterBottom color="primary">
                {block.title}
              </Typography>
              <Box sx={{ 
                my: 2, 
                px: 2, 
                py: 1.5, 
                bgcolor: 'background.paper',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}>
                <div dangerouslySetInnerHTML={{ __html: block.content }} />
              </Box>
              <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={() => handleEdit(block)}
                  size="small"
                >
                  Bearbeiten
                </Button>
                <Button 
                  variant="outlined" 
                  color="secondary"
                  onClick={() => handleRestoreDefault(block)}
                  size="small"
                >
                  Text zurücksetzen
                </Button>
              </Box>
            </Box>
            {index < textBlocks.length - 1 && <Divider />}
          </Box>
        ))}
      </Paper>

      <Dialog 
        open={isEditing} 
        onClose={() => setIsEditing(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Text Block</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Title"
              value={selectedBlock?.title || ''}
              disabled
              sx={{ mb: 2 }}
            />
            <Box sx={{ mb: 2, '& .ql-editor': { minHeight: '200px' } }}>
              <ReactQuill
                value={selectedBlock?.content || ''}
                onChange={(content: string) => setSelectedBlock(prev => prev ? {...prev, content} : null)}
                modules={quillModules}
                formats={quillFormats}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, action: () => {}, message: '' })}
      >
        <DialogTitle>Zurücksetzen bestätigen</DialogTitle>
        <DialogContent>
          <Typography>{confirmDialog.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog({ open: false, action: () => {}, message: '' })}>
            Abbrechen
          </Button>
          <Button onClick={() => confirmDialog.action()} color="primary" variant="contained">
            Zurücksetzen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmailTextBlockEditor; 