import React, { useState } from 'react';
import { Grid, Paper, Typography, Box, TextField, Button, Collapse, IconButton, Tooltip } from '@mui/material';
import { MitarbeiterStat } from '../types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from '@mui/icons-material/Info';

interface MitarbeiterGridProps {
    mitarbeiter: MitarbeiterStat[];
    selectedMitarbeiterId: number | null;
    onMitarbeiterSelect: (id: number | null) => void;
}

const MitarbeiterGrid: React.FC<MitarbeiterGridProps> = ({
    mitarbeiter,
    selectedMitarbeiterId,
    onMitarbeiterSelect,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showAll, setShowAll] = useState(false);

    const infoText = "Detaillierte Übersicht der Mitarbeiter:innen mit ihrer Bestätigungsquote. Klicken Sie auf eine Karte für weitere Details. Die Bestätigungsquote zeigt den Prozentsatz der Terminanfragen, die von der Person bestätigt wurden.";

    const filteredMitarbeiter = mitarbeiter
        .filter(ma => 
            ma.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => b.anzahl - a.anzahl);

    const displayedMitarbeiter = showAll 
        ? filteredMitarbeiter 
        : filteredMitarbeiter.slice(0, 10);

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h6">
                        Mitarbeiter:innen Detail
                    </Typography>
                    <Tooltip title={infoText}>
                        <IconButton size="small">
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <TextField
                    size="small"
                    placeholder="Mitarbeiter:in suchen..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: 250 }}
                />
            </Box>
            <Grid container spacing={2}>
                {displayedMitarbeiter.map((ma) => (
                    <Grid item xs={12} sm={6} key={ma.id}>
                        <Paper 
                            sx={{
                                p: 2,
                                cursor: 'pointer',
                                bgcolor: selectedMitarbeiterId === ma.id ? '#f0f7ff' : 'background.paper',
                                '&:hover': {
                                    bgcolor: '#f5f5f5',
                                    transition: 'background-color 0.3s'
                                }
                            }}
                            onClick={() => onMitarbeiterSelect(selectedMitarbeiterId === ma.id ? null : ma.id)}
                            elevation={selectedMitarbeiterId === ma.id ? 3 : 1}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {ma.name}
                                </Typography>
                                <Typography variant="body2" color="primary">
                                    {((ma.anzahlBestaetigt / ma.anzahlAnfragen) * 100).toFixed(1)}% Bestätigungsquote
                                </Typography>
                            </Box>
                            <Collapse in={selectedMitarbeiterId === ma.id}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                    <Box>
                                        <Typography variant="body2" color="textSecondary">
                                            Anfragen
                                        </Typography>
                                        <Typography variant="h6">
                                            {ma.anzahlAnfragen}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="body2" color="textSecondary">
                                            Bestätigt
                                        </Typography>
                                        <Typography variant="h6" color="success.main">
                                            {ma.anzahlBestaetigt}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Collapse>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            {filteredMitarbeiter.length > 10 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                        onClick={() => setShowAll(!showAll)}
                        endIcon={showAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                        {showAll ? 'Weniger anzeigen' : `${filteredMitarbeiter.length - 10} weitere anzeigen`}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default MitarbeiterGrid; 