// AngenommenCheckbox.tsx
import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Checkbox, FormControlLabel } from '@mui/material';
import { sendConfirmationEmail, sendRejectionEmail, sendCustomerConfirmationEmail, sendMitarbeiterConfirmationEmail } from './sendEmail';
import { sendEinzelauftrag } from './sendEinzelauftrag';
import { showOverlay, updateOverlay, hideOverlay } from './overlayEventService';

export const CHECKBOX_CLICKED_EVENT = 'ANGENOMMEN_CHECKBOX_CLICKED';

interface RecordType {
    id: number;
    angenommen: boolean;
    mitarbeiter_id: number;
    terminvorschlag_id: number;
}

const AngenommenCheckbox: React.FC<{ record: RecordType }> = ({ record }) => {
    const [selected, setSelected] = useState<boolean>(record.angenommen);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        e.stopPropagation();
        window.dispatchEvent(new CustomEvent(CHECKBOX_CLICKED_EVENT));

        // Update local state immediately
        setSelected(checked);

        try {
            // Update the database first
            await dataProvider.update('relmitarbeiterstatus', {
                id: record.id,
                data: { angenommen: checked },
                previousData: record,
            });

            if (checked) {
                showOverlay();
                try {
                    updateOverlay({ text: 'Sende Bestätigung an Server...', completed: true });
                    await sendConfirmationEmail(
                        dataProvider,
                        notify,
                        record.mitarbeiter_id,
                        record.terminvorschlag_id
                    );
                    updateOverlay({ text: 'Bestätigung an Server gesendet', completed: true });

                    // Prepare data for sendEinzelauftrag
                    const einzelauftragData = {
                        mitarbeiterId: record.mitarbeiter_id,
                        terminvorschlagId: record.terminvorschlag_id,
                        produktName: '', // Will be fetched in sendEinzelauftrag
                        termin: '', // Will be fetched in sendEinzelauftrag
                        museum: '', // Will be fetched in sendEinzelauftrag
                        mitarbeiterEmail: '', // Will be fetched in sendEinzelauftrag
                        mitarbeiterVorname: '', // Will be fetched in sendEinzelauftrag
                        mitarbeiterNachname: '', // Will be fetched in sendEinzelauftrag
                    };

                    updateOverlay({ text: 'Erstelle Einzelauftrag...', completed: true });
                    const slug = await sendEinzelauftrag(dataProvider, einzelauftragData);
                    updateOverlay({ text: 'Einzelauftrag erstellt', completed: true });

                    // Re-fetch latest terminvorschlag data
                    const { data: currentTerminvorschlag } = await dataProvider.getOne('terminvorschlag', { id: record.terminvorschlag_id });
                    const { data: bestellung } = await dataProvider.getOne('bestellung', { id: currentTerminvorschlag.bestellung_id });
                    const { data: produkt } = await dataProvider.getOne('produkt', { id: bestellung.produkt_id });

                    // Re-fetch accepted employees
                    const { data: acceptedEmployees } = await dataProvider.getList('relmitarbeiterstatus', {
                        filter: { terminvorschlag_id: record.terminvorschlag_id, angenommen: true },
                        pagination: { page: 1, perPage: 1000 },
                        sort: { field: 'id', order: 'ASC' },
                    });

                    if (acceptedEmployees.length === produkt.anzahlmitarbeiter) {
                        updateOverlay({ text: 'Aktualisiere Terminvorschlag-Status...', completed: true });
                        await dataProvider.update('terminvorschlag', {
                            id: record.terminvorschlag_id,
                            data: { status: 'akzeptiert' },
                            previousData: currentTerminvorschlag,
                        });
                        updateOverlay({ text: 'Terminvorschlag-Status aktualisiert', completed: true });

                        await new Promise(resolve => setTimeout(resolve, 1000));

                        const { data: updatedTerminvorschlag } = await dataProvider.getOne('terminvorschlag', { id: record.terminvorschlag_id });

                        if (updatedTerminvorschlag.status === 'akzeptiert') {
                            updateOverlay({ text: 'Sende Bestätigungen an alle Beteiligten...', completed: true });
                            const { data: kunde } = await dataProvider.getOne('kunde', { id: bestellung.kunde_id });

                            // Send customer confirmation
                            await sendCustomerConfirmationEmail(
                                dataProvider,
                                notify,
                                kunde,
                                currentTerminvorschlag,
                                produkt,
                                acceptedEmployees
                            );

                            // Send mitarbeiter confirmations
                            for (const employee of acceptedEmployees) {
                                const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', {
                                    id: employee.mitarbeiter_id
                                });
                                await sendMitarbeiterConfirmationEmail(
                                    dataProvider,
                                    notify,
                                    mitarbeiter,
                                    kunde,
                                    currentTerminvorschlag,
                                    produkt,
                                    acceptedEmployees
                                );
                            }
                            updateOverlay({ text: 'Alle Bestätigungen versendet', completed: true });

                            // Get all terminvorschläge for this bestellung
                            const { data: allTerminvorschlaege } = await dataProvider.getList('terminvorschlag', {
                                filter: { bestellung_id: bestellung.id },
                                pagination: { page: 1, perPage: 1000 },
                                sort: { field: 'id', order: 'ASC' },
                            });

                            // Get all accepted employees for this bestellung
                            const acceptedEmployeesSet = new Set<number>();
                            for (const termin of allTerminvorschlaege) {
                                const { data: acceptedForTermin } = await dataProvider.getList('relmitarbeiterstatus', {
                                    filter: {
                                        terminvorschlag_id: termin.id,
                                        bestaetigt: true,
                                        angenommen: true,
                                    },
                                    pagination: { page: 1, perPage: 1000 },
                                });
                                acceptedForTermin.forEach(emp => acceptedEmployeesSet.add(emp.mitarbeiter_id));
                            }

                            // Process each Terminvorschlag
                            for (const termin of allTerminvorschlaege) {
                                // Get all employees for this Terminvorschlag
                                const { data: employees } = await dataProvider.getList('relmitarbeiterstatus', {
                                    filter: {
                                        terminvorschlag_id: termin.id,
                                        bestaetigt: true,
                                    },
                                    pagination: { page: 1, perPage: 1000 },
                                    sort: { field: 'id', order: 'ASC' },
                                });

                                // Send rejections
                                for (const employee of employees) {
                                    if (!acceptedEmployeesSet.has(employee.mitarbeiter_id) && !employee.angenommen) {
                                        // Send rejection email only if the employee wasn't accepted for any Terminvorschlag
                                        await sendRejectionEmail(
                                            dataProvider,
                                            notify,
                                            employee.mitarbeiter_id,
                                            termin.id
                                        );
                                    }
                                }
                            }

                            notify('Alle Beteiligten wurden benachrichtigt', { type: 'success' });
                        } else {
                            // Status is not 'akzeptiert'; do not send rejection emails
                            notify('Terminvorschlag wurde bereits verarbeitet.', { type: 'info' });
                        }
                    } else {
                        // Not enough employees yet; do nothing or provide feedback
                        notify('Noch nicht genügend Mitarbeiter angenommen.', { type: 'info' });
                    }
                } catch (error) {
                    setSelected(!checked);
                    updateOverlay({ text: 'Fehler aufgetreten!', completed: true });
                    console.error('Error:', error);
                    notify('Ein Fehler ist aufgetreten', { type: 'error' });
                    hideOverlay();
                }
            }

            notify(checked ? 'Angenommen status updated' : 'Mitarbeiter abgewählt', { type: 'success' });
            refresh();
        } catch (error) {
            setSelected(!checked);
            console.error('Error updating angenommen status:', error);
            notify('Fehler beim Aktualisieren des Angenommen-Status', { type: 'error' });
        }
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={selected}
                    onChange={handleChange}
                    onClick={handleClick}
                />
            }
            label="Angenommen"
            onClick={handleClick}
        />
    );
};

export default AngenommenCheckbox;