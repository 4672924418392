import React, { useState } from 'react';
import { Datagrid, TextField, DatagridProps, useRecordContext, SelectInput } from 'react-admin';
import ZugewieseneMitarbeiter from './ZugewieseneMitarbeiter';
import { formatDateTime } from '../utils/dateUtils';
import moment from 'moment-timezone';
import { Select, MenuItem, FormControl, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { sendConfirmationEmail, sendCustomerConfirmationEmail, sendMitarbeiterConfirmationEmail } from './sendEmail';
import { showOverlay, updateOverlay, hideOverlay } from './overlayEventService';

interface TerminvorschlagRecord {
    id: number;
    status: string;
    vorschlagdatetime: string;
}

const rowStyle = (record: TerminvorschlagRecord): React.CSSProperties => {
    if (!record) return {};
    switch (record.status) {
        case 'match':
            return { backgroundColor: 'lightgreen' };
        case 'akzeptiert':
            return { backgroundColor: 'lightblue' };
        default:
            return {};
    }
};

interface LocalDateFieldProps {
    source: string;
}

const LocalDateField: React.FC<LocalDateFieldProps> = ({ source }) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;
    const formattedDate = moment.utc(record[source])
        .tz('Europe/Berlin')
        .format('DD.MM.YYYY HH:mm');
    return <span>{formattedDate}</span>;
};

const StatusSelect: React.FC<{ record: any }> = ({ record }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const [showForceDialog, setShowForceDialog] = useState(false);

    const handleChange = async (event: any) => {
        try {
            const newStatus = event.target.value;
            await dataProvider.update('terminvorschlag', {
                id: record.id,
                data: { status: newStatus },
                previousData: record,
            });

            if (newStatus === 'akzeptiert') {
                const { data: terminvorschlag } = await dataProvider.getOne('terminvorschlag', { 
                    id: record.id 
                });
                const { data: bestellung } = await dataProvider.getOne('bestellung', { 
                    id: terminvorschlag.bestellung_id 
                });
                const { data: produkt } = await dataProvider.getOne('produkt', { 
                    id: bestellung.produkt_id 
                });
                const { data: kunde } = await dataProvider.getOne('kunde', { 
                    id: bestellung.kunde_id 
                });

                const { data: acceptedEmployees } = await dataProvider.getList('relmitarbeiterstatus', {
                    filter: { 
                        terminvorschlag_id: record.id,
                        angenommen: true 
                    },
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'id', order: 'ASC' },
                });

                // Send to customer
                await sendCustomerConfirmationEmail(
                    dataProvider,
                    notify,
                    kunde,
                    terminvorschlag,
                    produkt,
                    acceptedEmployees
                );

                // Send to each employee using the new function
                for (const employee of acceptedEmployees) {
                    const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', {
                        id: employee.mitarbeiter_id
                    });
                    await sendMitarbeiterConfirmationEmail(
                        dataProvider,
                        notify,
                        mitarbeiter,
                        kunde,
                        terminvorschlag,
                        produkt,
                        acceptedEmployees
                    );
                }

                notify('Buchungsbestätigungen wurden an alle Beteiligten versendet', { type: 'success' });
            }

            notify('Status updated', { type: 'success' });
            refresh();
        } catch (error) {
            console.error('Error:', error);
            notify('Error updating status', { type: 'error' });
        }
    };

    const handleForceConfirmation = async () => {
        showOverlay();
        try {
            const { data: terminvorschlag } = await dataProvider.getOne('terminvorschlag', { 
                id: record.id 
            });
            const { data: bestellung } = await dataProvider.getOne('bestellung', { 
                id: terminvorschlag.bestellung_id 
            });
            const { data: produkt } = await dataProvider.getOne('produkt', { 
                id: bestellung.produkt_id 
            });
            const { data: kunde } = await dataProvider.getOne('kunde', { 
                id: bestellung.kunde_id 
            });

            const { data: acceptedEmployees } = await dataProvider.getList('relmitarbeiterstatus', {
                filter: { 
                    terminvorschlag_id: record.id,
                    angenommen: true 
                },
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'id', order: 'ASC' },
            });

            updateOverlay({ text: 'Aktualisiere Terminvorschlag-Status...', completed: true });
            await dataProvider.update('terminvorschlag', {
                id: record.id,
                data: { status: 'akzeptiert' },
                previousData: record,
            });

            updateOverlay({ text: 'Sende Bestätigungen an alle Beteiligten...', completed: true });

            // Send to customer
            await sendCustomerConfirmationEmail(
                dataProvider,
                notify,
                kunde,
                terminvorschlag,
                produkt,
                acceptedEmployees
            );

            // Send to each employee
            for (const employee of acceptedEmployees) {
                const { data: mitarbeiter } = await dataProvider.getOne('mitarbeiter', {
                    id: employee.mitarbeiter_id
                });
                await sendMitarbeiterConfirmationEmail(
                    dataProvider,
                    notify,
                    mitarbeiter,
                    kunde,
                    terminvorschlag,
                    produkt,
                    acceptedEmployees
                );
            }
            updateOverlay({ text: 'Alle Bestätigungen versendet', completed: true });

            notify('Buchungsbestätigung wurde erfolgreich erzwungen', { type: 'success' });
            refresh();
        } catch (error) {
            console.error('Error:', error);
            notify('Fehler beim Erzwingen der Buchungsbestätigung', { type: 'error' });
        } finally {
            setShowForceDialog(false);
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <FormControl>
                    <Select
                        value={record.status}
                        onChange={handleChange}
                        onClick={(e) => e.stopPropagation()}
                        size="small"
                    >
                        <MenuItem value="vorgeschlagen">Vorgeschlagen</MenuItem>
                        <MenuItem value="akzeptiert">Akzeptiert</MenuItem>
                        <MenuItem value="abgelehnt">Abgelehnt</MenuItem>
                        <MenuItem value="match">Match</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowForceDialog(true);
                    }}
                    sx={{ 
                        minWidth: 'auto',
                        padding: '4px 8px',
                        fontSize: '0.75rem'
                    }}
                >
                    Bestätigungen senden
                </Button>
            </Box>

            <Dialog
                open={showForceDialog}
                onClose={() => setShowForceDialog(false)}
                onClick={(e) => e.stopPropagation()}
            >
                <DialogTitle>Buchungsbestätigung manuell auslösen</DialogTitle>
                <DialogContent>
                    <Typography>
                        Bist du sicher, dass du die Buchungsbestätigung mannuell auslösen möchtest? Es wurden möglicherweise nicht genügend Mitarbeiter:innen angenommen.
                        Dies Funktion ist für Ausnahmefälle vorgesehen.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowForceDialog(false)}>Abbrechen</Button>
                    <Button 
                        onClick={handleForceConfirmation}
                        color="warning"
                        variant="contained"
                    >
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const StatusSelectField = ({ source }: { source: string }) => {
    const record = useRecordContext();
    if (!record) return null;
    return <StatusSelect record={record} />;
};

const TerminvorschlagDatagrid: React.FC<DatagridProps> = (props) => (
    <Datagrid {...props} rowStyle={rowStyle}>
        <TextField source="id" />
        <LocalDateField source="vorschlagdatetime" />
        <StatusSelectField source="status" />
        <ZugewieseneMitarbeiter />
    </Datagrid>
);

export default TerminvorschlagDatagrid;