import React from 'react';
import { Grid, Paper, Typography, Box, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip as ChartTooltip, Legend } from 'recharts';
import { StatusData } from '..';

interface StatusPieChartProps {
    data: StatusData[];
}

const COLORS = {
    'Abgeschlossen': '#00C49F',  // Grün
    'Storniert': '#FF8042'       // Orange
};

const StatusPieChart: React.FC<StatusPieChartProps> = ({ data }) => {
    const infoText = "Zeigt die Verteilung der Bestellungen nach ihrem Status an. Nicht einbezogen sind Bestlellungen die weder abgeschlossen noch storniert sind.";

    return (
        <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
                <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Bestellungen nach Status
                    </Typography>
                    <Tooltip title={infoText}>
                        <IconButton size="small">
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                {data.every(item => item.value === 0) ? (
                    <Typography color="text.secondary" align="center">
                        Keine Daten für dieses Jahr verfügbar
                    </Typography>
                ) : (
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={data}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                label={({ name, value }) => `${name}: ${value}`}
                            >
                                {data.map((entry) => (
                                    <Cell key={entry.name} fill={COLORS[entry.name as keyof typeof COLORS]} />
                                ))}
                            </Pie>
                            <ChartTooltip />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                )}
            </Paper>
        </Grid>
    );
};

export default StatusPieChart; 