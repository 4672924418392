import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import { hideOverlay } from './overlayEventService';

interface LoadingOverlayProps {
    messages: { text: string; completed: boolean }[];
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ messages }) => {
    const [canComplete, setCanComplete] = useState(false);
    const allCompleted = messages.length > 0 && messages.every(message => message.completed);
    const isFullProcess = messages.some(message => 
        message.text.includes('Bestätigung') || message.text.includes('Einzelauftrag')
    );

    useEffect(() => {
        if (allCompleted) {
            // Set minimum delay of 3 seconds before enabling the button
            const timer = setTimeout(() => {
                setCanComplete(true);
            }, 3000);

            return () => clearTimeout(timer);
        } else {
            setCanComplete(false);
        }
    }, [allCompleted]);

    const handleComplete = () => {
        hideOverlay(() => {
            // Force a complete refresh of the page
            window.location.reload();
        });
    };

    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(0, 0, 0, 0.7)"
            zIndex={9999}
        >
            <Box 
                bgcolor="white" 
                p={4} 
                borderRadius={2} 
                maxWidth={400}
                maxHeight="80vh"
                display="flex"
                flexDirection="column"
                gap={3}
            >
                <Typography variant="h6" gutterBottom>
                    Verarbeitung läuft...
                </Typography>
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    gap={2}
                    sx={{ 
                        overflowY: 'auto',
                        maxHeight: '50vh',
                        mb: 2
                    }}
                >
                    {messages.map((message, index) => (
                        <Box 
                            key={index} 
                            display="flex" 
                            alignItems="center" 
                            gap={2}
                            sx={{
                                opacity: index === messages.length - 1 ? 1 : 0.7,
                                transition: 'opacity 0.3s'
                            }}
                        >
                            {message.completed ? (
                                <span style={{ color: 'green', fontSize: '1.2em' }}>✓</span>
                            ) : (
                                <CircularProgress size={20} />
                            )}
                            <Typography>
                                {message.text}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!allCompleted || !canComplete}
                    onClick={handleComplete}
                    sx={{ minWidth: 200 }}
                >
                    {!allCompleted ? 'Bitte warten...' : 
                     !canComplete ? 'Wird verarbeitet...' : 
                     'Geschafft'}
                </Button>
            </Box>
        </Box>
    );
};

export default LoadingOverlay;
