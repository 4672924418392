export const OVERLAY_EVENTS = {
    SHOW: 'SHOW_LOADING_OVERLAY',
    UPDATE: 'UPDATE_LOADING_OVERLAY',
    HIDE: 'HIDE_LOADING_OVERLAY'
};

export interface OverlayMessage {
    text: string;
    completed: boolean;
}

export const showOverlay = () => {
    window.dispatchEvent(new CustomEvent(OVERLAY_EVENTS.SHOW));
};

export const updateOverlay = (message: OverlayMessage) => {
    window.dispatchEvent(new CustomEvent(OVERLAY_EVENTS.UPDATE, { 
        detail: message 
    }));
};

export const hideOverlay = (callback?: () => void) => {
    window.dispatchEvent(new CustomEvent(OVERLAY_EVENTS.HIDE, {
        detail: { callback }
    }));
};